import React from 'react';
import NavBar from "./NavBar";
import Container from "@mui/material/Container";
import HeroSection from "./HeroSection";
import SecondSection from "./SecondSection";
import ThirdSection from "./ThirdSection";
import ForthSection from "./ForthSection";
import FifthSection from "./FifthSection";
import Footer from "./Footer";



function LandingPage(){
    return(
        <div>
            <NavBar/>
            <Container  disableGutters sx={{ maxWidth:'2000px', padding: 0 }} maxWidth="false">
                <HeroSection/>
                <SecondSection/>
                <ThirdSection />
                <ForthSection />
                <FifthSection/>
            </Container>
            <Footer/>

        </div>
    )
}

export default LandingPage