import React, {useEffect, useState} from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import calculator from '../../OlyvesImages/calculator.png'
import like from '../../OlyvesImages/like.png'
import monies from '../../OlyvesImages/monies.png'
import card from '../../OlyvesImages/card.png'


function ForthSection() {
    const [currentIndex, setCurrentIndex] = useState(0)


    const slides = [
        {
            iconUrl: calculator,
            slideHeading: 'Accurate Computations',
            slideText: 'Dump error-prone legacy payroll computations and embrace accurate digital technologies.'
        },
        {
            iconUrl: monies,
            slideHeading: 'Affordable Pricing',
            slideText: 'An indigenous payroll solution is as important to us as your budget. Olyves is priced to suit your business.'
        },
        {
            iconUrl: card,
            slideHeading: 'Guaranteed Security',
            slideText: 'Your data security is our priority. Our system is armed with advanced technologies that you can trust.'
        },
        {
            iconUrl: like,
            slideHeading: 'Reliable Support',
            slideText: 'Sometimes, it’s nice to talk to a real person and we are happy to help you at Olyves. Our dedicated customer support team is a click away'
        }
    ]

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === slides.length - 1 ? 0 : prevIndex + 1
            );
        }, 5000);

        return () => clearInterval(interval);
    }, [slides]);

    const nextSlide = () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex)
    }
    return (
        <div className='flex justify-center items-center'>
            <div className='bg-primary w-screen h-[600px] my-32 relative flex justify-center items-center mini-tablet:h-[500px] mobile:h-[500px] '>
                <div className='h-[225px] w-[225px] rounded-full bg-greenAccent absolute top-[20%] left-[40%] filter blur-[125.5px] '></div>
                <div className='h-[225px] w-[225px] rounded-full bg-greenAccent absolute bottom-[20%] right-[10%] filter blur-[125.5px] mobile:hidden'></div>

                <div className='flex justify-between text-white items-center relative z-10 w-[70%] h-[60%]
                mini-tablet:h-auto mini-tablet:w-[80%] tablets:w-[80%] tablets:h-auto
                laptops:w-[80%] laptops:h-auto mobile:w-[90%]
                mobile:flex-col mobile:justify-center mobile:text-center'>
                    <h1 className='font-cabin font-bold text-4xl w-[45%] mini-tablet:text-2xl  mobile:m-8'>Features</h1>
                    <div onClick={nextSlide} className='w-[55%] h-full  flex flex-col justify-center items-center mini-tablet:w-[70%] tablets:w-[70%] mobile:w-full mobile:h-[300px] mobile:text-left  '>
                        <div className='h-full w-full bg-features shadow-feature p-12 rounded-lg z-10 relative mini-tablet:p-6 tablets:p-8 mobile:p-6 '>
                            <img className='h-[48px] w-[48px] my-4 mini-tablet:my-1.5 tablets:my-2' src={slides[currentIndex].iconUrl}/>
                            <div className='my-16 mini-tablet:my-8 tablets:my-10 laptops:my-10 mobile:my-8'>
                                <h1 className='font-lato font-bold text-2xl my-4 mini-tablet:text-xl mini-tablet:my-2 '>{slides[currentIndex].slideHeading}</h1>
                                <p className='font-lato text-[#BDC6D3] text-base w-4/5 mini-tablet:w-full mini-tablet:text-sm mini-tablet:w-[90%] tablets:w-[95%] mobile:w-full'>{slides[currentIndex].slideText}</p>
                            </div>
                        </div>
                        <div className='absolute w-[50%] h-[10px] bg-transparent rounded z-0 right-8 mini-tablet:right-10 tablets:right-10 -bottom-3 mobile:h-[8px] mobile:w-[88%] mobile:-bottom-16  mobile:right-6'></div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default ForthSection