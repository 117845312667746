import React, {useEffect, useState, useRef} from 'react';
import Sec301 from '../../OlyvesImages/Sec3(01).png'
import Sec302 from '../../OlyvesImages/Sec3(02).png'
import Sec303 from '../../OlyvesImages/Sec3(03).png'
import Sec304 from '../../OlyvesImages/Sec3(04).png'
import {TfiArrowCircleLeft, TfiArrowCircleRight} from "react-icons/tfi";

function ThirdSection() {
    const [currentIndex, setCurrentIndex] = useState(0)
    const carouselRef = useRef(null);
    const [clickedHr, setClickedHr] = useState(null);
    const slides = [
        {Url: Sec301, slideNumber: '01', slideHeading1: 'Create your account in', slideHeading2: 'less than 2 mins'},
        {Url: Sec302, slideNumber: '02', slideHeading1: 'Choose your preferred ', slideHeading2: 'payroll settings'},
        {Url: Sec303, slideNumber: '03', slideHeading1: 'Onboard your employees ', slideHeading2: 'at your pace'},
        {Url: Sec304, slideNumber: '04', slideHeading1: 'Roll out accurate payroll payments'}]


    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => prevIndex === slides.length - 1 ? 0 : prevIndex + 1);
            setClickedHr((prevIndex) => prevIndex === slides.length - 1 ? 0 : prevIndex + 1)
        }, 5000);

        return () => clearInterval(interval);
    }, [slides]);

    const prevSlide = () => {
        const isFirstSlide = currentIndex === 0
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex)
    }
    const nextSlide = () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex)
    }
    const handleClick = (slideIndex) => {
        setCurrentIndex(slideIndex)
        setClickedHr(slideIndex);
    }

    return (<div className='flex justify-center items-center my-4'>
        <div className=' h-auto w-screen text-center flex justify-center items-center flex-col mobile:px-4'>
            <h1 className='font-cabin text-primary text-4xl font-bold m-10
                mini-tablet:text-3xl
                tablets:text-3xl
                mobile:text-3xl mobile:m-12
                '>Getting Started on Olyves</h1>
            <div
                className='flex justify-between w-[75%] my-28 mini-tablet:w-[85%] mini-tablet:my-12 tablets:w-[80%] tablets:my-16 laptops:w-[85%] mobile:flex-col mobile:w-[95%] mobile:my-6 mobile:mx-10'>
                <div className='flex flex-col justify-center items-start text-left w-[45%] mobile:w-full mobile:mx-4'>
                    <h1 className='font-lato font-bold text-secondary text-3xl mini-tablet:text-xl tablets:text-2xl laptops:text-2xl '>{slides[currentIndex].slideNumber}</h1>
                    <div className='w-full my-8 mobile:w-[80%]'>
                        <h1 className='font-lato font-semibold text-4xl text-primary  w-[85%] tracking-wide
                    mini-tablet:w-[90%] mini-tablet:text-lg
                    tablets:text-xl
                    laptops:text-3xl
                    mobile:w-full mobile:text-2xl mobile:w-[80%] '
                        >{slides[currentIndex].slideHeading1}</h1>
                        <h1 className='font-lato font-semibold text-4xl text-primary  w-[85%] tracking-wide
                    mini-tablet:w-[90%] mini-tablet:text-lg
                    tablets:text-xl
                    laptops:text-3xl
                    mobile:w-full mobile:text-2xl mobile:w-[80%] '
                        >{slides[currentIndex].slideHeading2}</h1>
                    </div>

                    <div className='flex mobile:hidden'>
                        {slides?.map((slide, slideIndex) => (<hr
                            key={slideIndex}
                            onClick={() => handleClick(slideIndex)}
                            className={`w-16 mini-tablet:w-10 tablets:w-14 rounded-full border-4 border-[#ECF0F1] hover:border-secondary mx-0.5  cursor-pointer mt-4   ${slideIndex === clickedHr ? 'border-secondary' : 'border-[#ECF0F1]'}`}
                        />))}
                    </div>
                </div>
                <div className='hidden shadow-box rounded-lg relative group mt-4 mobile:block '>
                    <div
                        className=' group-hover:block absolute top[50%] -translate-x-0 translate-y-[-50%] top-[50%] right-16  rounded-full p-0.5 bg-[#e2e7e8] text-white cursor-pointer'>
                        <TfiArrowCircleLeft onClick={prevSlide} size={25}/>
                    </div>
                    <div
                        className='group-hover:block absolute top[50%] -translate-x-0 translate-y-[-50%] top-[50%] right-5  rounded-full p-0.5 bg-[#e2e7e8] text-white cursor-pointer'>
                        <TfiArrowCircleRight onClick={nextSlide} size={25}/>
                    </div>
                </div>
                <div className='overflow-hidden h-full w-[55%] rounded-2xl shadow-xl mobile:w-full  mobile:mb-10 mobile:mt-7'>
                    <div className={`flex transition-transform ease-out duration-500 `}
                         ref={carouselRef}
                         style={{transform: `translateX(-${currentIndex * 100}%)`,}}>
                        {slides.map((slide, index) => (
                            <div key={index} className="w-full h-full flex-shrink-0">
                                <img
                                    className='object-cover w-full h-full rounded-2xl '
                                    src={slide.Url}/>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </div>

    </div>)
}

export default ThirdSection