import React from 'react';
import SecondSectionTile from "./SecondSectionTile";
import Card from '../../OlyvesImages/card.png'
import Receipt from '../../OlyvesImages/receipt.png'
import Check from '../../OlyvesImages/Icon.png'
import Chart from '../../OlyvesImages/chart.png'




function SecondSection() {
    return (
        <div className='flex justify-center items-center mt-32 mb-16 laptops:mt-12 mobile:my-8'>
            <div className='relative  h-auto mini-tablet:w-screen tablets:w-screen laptops:w-screen'>
                <div className='h-[450px] w-[450px] rounded-full bg-accent absolute top-[32%] left-[38%] filter blur-[125.5px] mobile:hidden'></div>
                <div className='text-center relative z-2'>
                    <h1 className='font-cabin font-bold m-16 text-4xl text-primary
                    mini-tablet:text-3xl
                    tablets:text-3xl tablets:m-8
                    laptops:m-8
                    mobile:text-3xl mobile:m-10
                    '>Your Pain Points and Our Solution</h1>
                    <div className='flex justify-evenly m-32 flex-wrap mini-tablet:m-10 tablets:m-16 laptops:m-16 mobile:flex-col mobile:m-4 mobile:p-5'>
                        <SecondSectionTile
                            IconImage={Card}
                            HeadingText={'Salary payment and remittances in one click'}
                            BodyText1={'Say bye to error-filled legacy systems & excel sheets and hello to sleek automations.'}
                            BodyText2={'You can now fund your payroll with bulk payment, process salaries, make contributions, and remit taxes in one click!'}
                        />
                        <SecondSectionTile
                            IconImage={Receipt}
                            HeadingText={'Generate employee pay slips on the go'}
                            BodyText1={'No more missing pay slips; send instant, digital pay slips to employees automatically.'}
                            BodyText2={'Your staff can now enjoy the privilege of your transparent payroll system with detailed breakdown of their: net pay, tax remittances, deductions, contributions,etc.'}
                        />
                        <SecondSectionTile
                            IconImage={Chart}
                            HeadingText={'Spot your important data at a glance'}
                            BodyText1={'Why scramble for your data when you need them urgently?'}
                            BodyText2={'Access detailed breakdowns of all employee cost at a glance and download reports quickly for easy management presentation and decision-making.'}
                        />
                        <SecondSectionTile
                            IconImage={Check}
                            HeadingText={'All stakeholders can retain their approval rights.'}
                            BodyText1={'Ditch difficult design workflows for hitch-free payroll approvals.'}
                            BodyText2={'With Olyves, you can easily create multiple checks throughout the approval process for all stakeholders.'}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SecondSection