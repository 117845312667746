import React, {useEffect} from 'react';
import logo from '../OlyvesImages/logo.png'
import Footer from "./LandingPage/Footer";
import {CgClose} from "react-icons/cg";
import {Link} from "react-router-dom";


function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='flex justify-center items-center flex-col'>
            <nav className='bg-[#edffef] w-screen h-[75px] flex justify-end px-10 py-5 mobile:h-[60px] '>
                <Link to={"/"}>
                    <CgClose size={25} color='#071932'/>
                </Link>
            </nav>
            <div className='w-[80%]  mt-16 mobile:mx-8 mobile:w-[90%]  '>
                <div className='mt-20 mb-28 mobile:my-8'>
                    <img className='h-[55px] w-[120px] my-12 mobile:h-[40px] mobile:w-[90px]' src={logo}/>
                </div>
                <div className='mt-24 mb-16 mobile:my-4'>
                    <div className="my-24 mobile:my-12">
                        <h1 className='font-cabin font-bold text-5xl text-primary  mobile:text-2xl my-6 mobile:my-2 '>PRIVACY
                            POLICY</h1>
                        <p className="font-lato text-md italic text-text mobile:text-sm">Last updated on 10 June
                            2023</p>
                    </div>

                    <div className='w-[100%] my-4'>
                        <h2 className='font-cabin font-bold text-primary text-3xl my-8 mobile:text-2xl'>Introduction</h2>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>Welcome to the privacy policy of
                            Olyves Technologies Limited (collectively referred to as ”<span
                                className="text-secondary">Olyves</span>”, “we”, “us” or “our” in this privacy policy).
                        </p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>Olyves respects your privacy and
                            is committed to protecting your personal data. This privacy policy will inform you as to how
                            we look after your personal data when you visit our website (regardless of where you visit
                            it from) and tell you about your privacy rights and how the law protects you.</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>This privacy policy is provided
                            in a layered format so you can browse through to the specific areas set out below. Please
                            also use the Glossary to understand the meaning of some of the terms used in this privacy
                            policy.</p>
                    </div>
                    <div className='w-[100%] my-16'>
                        <h2 className='font-cabin font-bold text-primary text-3xl my-8  mobile:text-2xl'>Outline</h2>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'> 1. IMPORTANT INFORMATION AND
                            WHO WE ARE</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>2. THE DATA WE COLLECT ABOUT
                            YOU</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>3. HOW IS YOUR PERSONAL DATA
                            COLLECTED?</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'> 4. HOW WE USE YOUR PERSONAL
                            DATA</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>5. DISCLOSURES OF YOUR PERSONAL
                            DATA</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'> 6. INTERNATIONAL TRANSFERS</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'> 7. DATA SECURITY</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>8. DATA RETENTION</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'> 9. YOUR LEGAL RIGHTS</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'> 10. GLOSSARY</p>

                    </div>
                    <div className='w-[100%] my-16'>
                        <h1 className='font-cabin font-bold text-primary text-3xl my-8 mobile:text-2xl'>1. IMPORTANT
                            INFORMATION AND WHO WE ARE</h1>
                        <div className=" my-10">
                            <h2 className='font-cabin font-bold text-primary text-2xl my-4 mobile:text-xl'>Purpose Of
                                This Privacy Policy</h2>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>This privacy policy aims to
                                give you information on how Olyves collects and processes your personal data through
                                your use of this website, including any data you may provide through this website when
                                you sign up to our newsletter, apply for or purchase our services, or other
                                circumstances that may involve disclosing your personal information.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>This website is not intended
                                for children and we do not knowingly collect data relating to children.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>It is important that you
                                read this privacy policy together with any other privacy policy or fair processing
                                policy we may provide on specific occasions when we are collecting or processing
                                personal data about you so that you are fully aware of how and why we are using your
                                data. This privacy policy supplements other notices and privacy policies and is not
                                intended to override them.</p>

                        </div>
                        <div className=" my-10">
                            <h2 className='font-cabin font-bold text-primary text-2xl my-4  mobile:text-xl'>Controller</h2>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>Olyves is the controller and
                                responsible for your personal data.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>We have appointed a data
                                privacy manager who is responsible for overseeing questions in relation to this privacy
                                policy. If you have any questions about this privacy policy, including any requests to
                                exercise your legal rights, please contact the data privacy manager using the details
                                set out below.</p>
                        </div>
                        <div className=" my-10">
                            <h2 className='font-cabin font-bold text-primary text-2xl my-4  mobile:text-xl'>Contact
                                Details</h2>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>If you have any questions
                                about this privacy policy or our privacy practices, please contact our data privacy
                                manager at <span className="text-secondary">legal@getolyves.com</span>.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>You have the right to make a
                                complaint at any time to the Nigeria Data Protection Bureau (“<span
                                    className="text-secondary">NDPB</span>”), the supervisory and regulatory authority
                                for data protection in Nigeria (<span
                                    className="text-secondary">https://ndpb.gov.ng/</span>). We would, however,
                                appreciate the chance to deal with your concerns before you approach the NDPB, so please
                                contact us in the first instance..</p>
                        </div>
                        <div className=" my-10">
                            <h2 className='font-cabin font-bold text-primary text-2xl my-4  mobile:text-xl'>Changes To
                                The Privacy Policy and Your Duty To Inform Us To Changes</h2>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>We keep our privacy policy
                                under regular review. Changes made will be reflected on this page and we shall notify
                                you of a change to this privacy policy by updating the date above this page. Historic
                                versions of the privacy policy can be obtained by contacting us.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>It is important that the
                                personal data we hold about you is accurate and current. Please keep us informed if your
                                personal data changes during your relationship with us.</p>
                        </div>
                        <div className=" my-10">
                            <h2 className='font-cabin font-bold text-primary text-2xl my-4  mobile:text-xl'>Third-Party
                                Links</h2>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>This website may include
                                links to third-party websites, plug-ins and applications. Clicking on those links or
                                enabling those connections may allow third parties to collect or share data about you.
                                We do not control these third-party websites and are not responsible for their privacy
                                statements. When you leave our website, we encourage you to read the privacy policy of
                                every website you visit.</p>
                        </div>
                    </div>
                    <div className='w-[100%] my-16'>
                        <h1 className='font-cabin font-bold text-primary text-3xl my-8 mobile:text-2xl'>2. THE DATA WE
                            COLLECT ABOUT YOU</h1>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>Personal data, or personal
                            information, means any information about an individual from which that person can be
                            identified. It does not include data where the identity has been removed (anonymous
                            data).</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>We may collect, use, store and
                            transfer different kinds of personal data about you which we have grouped together as
                            follows:</p>

                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>• <span
                            className="text-secondary">Identity Data</span> includes first name, maiden name, last name,
                            username or similar identifier, marital status, title, date of birth and gender.</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>• <span
                            className="text-secondary">Contact Data</span> includes billing address, delivery address,
                            email address and telephone numbers.</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>• <span
                            className="text-secondary">Financial Data</span> includes bank account and payment card
                            details.</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>• <span
                            className="text-secondary">Transaction Data</span> includes details about payments to and
                            from you and other details of products and services you have obtained from us.</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>• <span
                            className="text-secondary">Technical Data</span> includes internet protocol (IP) address,
                            your login data, browser type and version, time zone setting and location, browser plug-in
                            types and versions, operating system and platform, and other technology on the devices you
                            use to access this website.</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>• <span
                            className="text-secondary">Profile Data</span> includes your username and password,
                            purchases or orders made by you, your interests, preferences, feedback and survey responses.
                            We also collect unique governmental/ institutional identification details including your
                            National Identification Number, Tax Identification Number, Pension PIN, and other related
                            details.</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>• <span
                            className="text-secondary">Usage Data</span> includes information about how you use our
                            website, products and services.</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>• <span
                            className="text-secondary">Marketing and Communications Data</span> includes your
                            preferences in receiving marketing from us and our third parties and your communication
                            preferences.</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>We also collect, use and
                            share <span className="text-secondary">Aggregated Data</span> such as statistical or
                            demographic data for any purpose. Aggregated Data could be derived from your personal data
                            but is not considered personal data in law as this data will not directly or indirectly
                            reveal your identity. For example, we may aggregate your Usage Data to calculate the
                            percentage of users accessing a specific website feature. However, if we combine or connect
                            Aggregated Data with your personal data so that it can directly or indirectly identify you,
                            we treat the combined data as personal data which will be used in accordance with this
                            privacy policy.</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>We do not collect any <span
                            className="text-secondary">Sensitive Personal Data</span> about you (this includes details
                            about your race or ethnicity, religious or philosophical beliefs, sex life, sexual
                            orientation, political opinions, trade union membership, information about your health, and
                            genetic and biometric data). Nor do we collect any information about criminal convictions
                            and offences.</p>
                        <div className='w-[90%] my-4'>
                            <h2 className='font-cabin font-bold text-primary text-2xl my-4  mobile:text-xl'>If You Fail
                                To Provide Personal Data</h2>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>Where we need to collect
                                personal data by law, or under the terms of a contract we have with you, and you fail to
                                provide that data when requested, we may not be able to perform the contract we have or
                                are trying to enter into with you (for example, to provide you with goods or services).
                                In this case, we may have to cancel your stay with us but we will notify you if this is
                                the case at the time..</p>
                        </div>

                    </div>
                    <div className='w-[100%] my-16'>
                        <h1 className='font-cabin font-bold text-primary text-3xl my-8 mobile:text-2xl'>3. HOW IS YOUR
                            PERSONAL DATA COLLECTED?</h1>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>We use different methods to
                            collect data from and about you including through:</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>• <span className="text-secondary">Direct interactions.</span> You
                            may
                            give us your Identity, Contact and Financial Data by filling in forms or by corresponding
                            with us by post, phone, email or otherwise. This includes personal data you provide when
                            you:</p>
                        <ul className=" ml-8">
                            <li><p className='font-lato text-lg text-text my-5  mobile:text-sm'>• apply for our
                                services;</p></li>
                            <li><p className='font-lato text-lg text-text my-5  mobile:text-sm'>• create an account on
                                our website;</p></li>
                            <li><p className='font-lato text-lg text-text my-5  mobile:text-sm'>• subscribe to our
                                service or publications;</p></li>
                            <li><p className='font-lato text-lg text-text my-5  mobile:text-sm'>• request marketing to
                                be sent to you;</p></li>
                            <li><p className='font-lato text-lg text-text my-5  mobile:text-sm'>• enter a competition,
                                promotion or survey; or</p></li>
                            <li><p className='font-lato text-lg text-text my-5  mobile:text-sm'> • give us feedback or
                                contact us.</p></li>
                        </ul>

                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>• <span className="text-secondary"> Automated technologies or
                            interactions</span>. As you interact with our website, we will automatically collect Technical Data
                            about your equipment, browsing actions and patterns. We collect this personal data by using
                            cookies and other similar technologies. We may also receive Technical Data about you if you
                            visit other websites employing our cookies. Please see our cookie policy [LINK] for further
                            details.</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>• <span className="text-secondary">Third parties or publicly
                            available sources</span>. We will receive personal data about you from various third parties and
                            public sources as set out below:</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>Technical Data from the
                            following parties:</p>

                        <div className="my-8 ml-8">
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>(a) analytics providers
                                based inside or outside Nigeria;</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>(b) advertising networks
                                based inside or outside Nigeria; and</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>(c) search information
                                providers based inside or outside Nigeria.</p>
                        </div>

                        <div className="my-8">
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>• Contact, Financial and
                                Transaction Data from providers of technical, payment and delivery
                                services based inside or outside Nigeria.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>• Identity and Contact Data
                                from data brokers or aggregators based inside or outside
                                Nigeria.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>• Any other legally
                                permissible ways by which we collect data.</p>
                        </div>
                        <div className="my-12">
                            <h2 className='font-cabin font-bold text-primary text-2xl my-4  mobile:text-xl'>Personal
                                Data
                                Of Other Persons Which You Share With Us</h2>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>We note that in utilising
                                our payroll services, you will be providing us with the personal data of other persons
                                such as your employees or staff members (“Employee Personal Data”). In respect of such
                                Employee Personal Data and according to the law:</p>

                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>(a) you are the controller
                                and responsible for the Employee Personal Data;</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>(b) the owners of the
                                Employee Personal Data (i.e., the employees or staff members) are the Data Subjects;
                                and</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>(c) we are the Data
                                Processor/Administrator, responsible for processing the Employee Personal Data for the
                                purpose and in the manner in which you prescribe.</p>

                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>At Olyves, we value the
                                privacy and protection of your personal data as well as Employee Personal Data. We are
                                committed to ensuring that any Employee Personal Data you share through our platforms is
                                processed in accordance with applicable data protection laws and regulations.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>By law, you are required to
                                have a lawful basis for the collection, processing and storage of Employee Personal
                                Data, and we have set out various lawful bases available to you:</p>

                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>● Consent: By providing
                                Employee Personal Data through our platforms, you represent and warrant that you have
                                obtained the necessary consents from the data subjects. You confirm that the collection,
                                processing, and storage of employee data are based on the explicit consent provided by
                                the data subjects.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>● Performance of Contract:
                                You acknowledge that the collection, processing, and storage of Employee Personal Data
                                are necessary for the performance of your contractual obligations with the data
                                subjects. This includes managing payroll, calculating benefits, and fulfilling other
                                employment-related obligations.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>● Compliance with Legal
                                Obligations: You agree that the collection, processing, and storage of employee
                                information on our platforms are necessary for compliance with legal obligations imposed
                                by law. This may include but is not limited to tax regulations, labour laws, and other
                                applicable employment-related legislation.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>● Legitimate Interests: You
                                acknowledge that we may process certain employee information based on our legitimate
                                interests, provided such processing does not override the rights and freedoms of the
                                data subjects. This may include internal administrative purposes, improving our
                                services, and ensuring the security of our systems.</p>

                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>We emphasise that you are
                                solely responsible for ensuring that you have a lawful basis for processing Employee
                                Personal Data and obtaining any necessary consents as required by law. Olyves will
                                process Employee Personal Data on your behalf strictly in accordance with your
                                instructions and the provisions of this privacy policy.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>It is important for you to
                                review your legal obligations and seek appropriate legal advice to ensure compliance
                                with the applicable data protection laws when providing Employee Personal Data on our
                                platforms.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>If you have any concerns or
                                questions regarding the lawful basis for processing employee information, please contact
                                our data protection officer at <span
                                    className="text-secondary">legal@getolyves.com</span>.</p>

                        </div>


                    </div>
                    <div className='w-[100%] my-16'>
                        <h1 className='font-cabin font-bold text-primary text-3xl my-8 mobile:text-2xl'>4. HOW WE USE
                            YOUR PERSONAL DATA</h1>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>We will only use your personal
                            data when the law allows us to. Most commonly, we will use your personal data in the
                            following circumstances:</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>We may collect, use, store and
                            transfer different kinds of personal data about you which we have grouped together as
                            follows:</p>

                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'> • Where we need to perform the
                            contract, we are about to enter into or have entered into with you.</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'> • Where it is necessary for our
                            legitimate interests (or those of a third party) and your interests and fundamental rights
                            do not override those interests.</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>• Where we need to comply with a
                            legal obligation.</p>

                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>Please use the Glossary to find
                            out more about the types of lawful basis that we will rely on to process your personal
                            data.</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>Generally, we do not rely on
                            consent as a legal basis for processing your personal data although we will get your consent
                            before sending third-party direct marketing communications to you via email or text message.
                            You have the right to withdraw consent to marketing at any time by contacting us.</p>

                        <h2 className='font-cabin font-bold text-primary text-2xl my-4  mobile:text-xl'>Purposes For
                            Which We Will Use Your Personal Data</h2>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>The purposes for which we plan
                            to use your personal data include to:</p>

                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'> ● register you as a new
                            customer.</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'> ● process and deliver your
                            order including (a) manage payments, fees and charges; and (b) collect and recover money
                            owed to us.</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>● manage our relationship with
                            you which will include: (a) notifying you about changes to our terms or privacy policy; and
                            (b) asking you to leave a review or take a survey.</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>● enable you to partake in a
                            prize draw, competition or promo.</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'> ● administer and protect our
                            business and this website (including troubleshooting, data analysis, testing, system
                            maintenance, support, reporting and hosting of data).</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'> ● deliver relevant website
                            content and advertisements to you and measure or understand the effectiveness of the
                            advertising we serve to you.</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>● use data analytics to improve
                            our website, products/services, marketing, customer relationships and experiences</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'> ● make suggestions and
                            recommendations to you about goods or services that may be of interest to you.</p>

                        <h2 className='font-cabin font-bold text-primary text-2xl my-4  mobile:text-xl'>Promotional
                            Offers From Us</h2>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>We may use your Identity,
                            Contact, Technical, Usage and Profile Data to form a view on what we think you may want or
                            need, or what may be of interest to you. This is how we decide which services and offers may
                            be relevant for you (we call this marketing). You will receive marketing communications from
                            us if you have requested information from us or services from us and you have not opted out
                            of receiving that marketing.</p>

                        <h2 className='font-cabin font-bold text-primary text-2xl my-4  mobile:text-xl'>Third-Party
                            Marketing</h2>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>We will get your express opt-in
                            consent before we share your personal data with any third party for marketing purposes:</p>

                        <h2 className='font-cabin font-bold text-primary text-2xl my-4  mobile:text-xl'>Opting Out</h2>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>You can ask us or third parties
                            to stop sending you marketing messages at any time by selecting the opt-out or unsubscribe
                            links shown to you upon receiving any one of our marketing emails and following the provided
                            prompts to complete the process. Where you opt out of receiving these marketing messages,
                            this will not apply to personal data provided to us as a result of a service purchase,
                            warranty registration, service experience or other transactions.</p>

                        <h2 className='font-cabin font-bold text-primary text-2xl my-4  mobile:text-xl'>Cookies</h2>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>You can set your browser to
                            refuse all or some browser cookies or to alert you when websites set or access cookies. If
                            you disable or refuse cookies, please note that some parts of this website may become
                            inaccessible or not function properly. For more information about the cookies we use, please
                            see our Cookie Policy [LINK].</p>

                        <h2 className='font-cabin font-bold text-primary text-2xl my-4  mobile:text-xl'>Change Of
                            Purpose</h2>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'> We will only use your personal
                            data for the purposes for which we collected it unless we reasonably consider that we need
                            to use it for another reason and that reason is compatible with the original purpose. If you
                            wish to get an explanation as to how the processing for the new purpose is compatible with
                            the original purpose, please contact us.</p>

                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>If we need to use your personal
                            data for an unrelated purpose, we will notify you and we will explain the legal basis which
                            allows us to do so. Please note that we may process your personal data without your
                            knowledge or consent, in compliance with the above rules, where this is required or
                            permitted by law.</p>

                    </div>
                    <div className='w-[100%] my-16'>
                        <h1 className='font-cabin font-bold text-primary text-3xl my-8 mobile:text-2xl'>5. DISCLOSURES OF YOUR PERSONAL DATA</h1>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>We may share your personal data with the parties set out below for the purposes set out in the table Purposes for which we will use your personal data above.</p>

                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>• Internal Third Parties as set out in the Glossary.</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>• External Third Parties as set out in the Glossary.</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>• Specific third parties listed in the Purposes for which we will use your personal data above.</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>• Third parties to whom we may choose to sell, transfer or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy policy.</p>

                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>

                    </div>
                    <div className='w-[100%] my-16'>
                        <h1 className='font-cabin font-bold text-primary text-3xl my-8 mobile:text-2xl'>6. INTERNATIONAL TRANSFERS</h1>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>We only share your personal data within Olyves, and this may include transferring your data outside Nigeria. Whenever we transfer your personal data out of Nigeria, we ensure a similar degree of protection is afforded to it by ensuring that we only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data similar to Nigeria.</p>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>Please contact us if you want further information on the specific mechanism used by us when transferring your personal data out of Nigeria.</p>

                    </div>
                    <div className='w-[100%] my-16'>
                        <h1 className='font-cabin font-bold text-primary text-3xl my-8 mobile:text-2xl'>7. DATA SECURITY</h1>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality. We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>

                    </div>
                    <div className='w-[100%] my-16'>
                        <h1 className='font-cabin font-bold text-primary text-3xl my-8 mobile:text-2xl'>8. DATA RETENTION</h1>
                        <h2 className='font-cabin font-bold text-primary text-2xl my-6 mobile:text-xl'>How Long Will You Use My Personal Data For?</h2>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'> We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation with respect to our relationship with you.</p>

                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.</p>

                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>In some circumstances you can ask us to delete your data: see Your Legal Rights below for further information.</p>

                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>In some circumstances, we will anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.</p>

                    </div>
                    <div className='w-[100%] my-16'>
                        <h1 className='font-cabin font-bold text-primary text-3xl my-8 mobile:text-2xl'>9. YOUR LEGAL
                            RIGHTS</h1>
                        <p className='font-lato text-lg text-text my-5  mobile:text-sm'>Under certain circumstances, you
                            have rights under data protection laws in relation to your personal data. Please click on
                            the links below to find out more about these rights:</p>

                        <div className="my-8">
                            <h2 className='font-cabin font-bold text-primary text-2xl my-6 mobile:text-xl'>Your Legal
                                Right</h2>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>You have the right to:</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'> <span className="text-secondary">Request access</span> to your
                                personal
                                data (commonly known as a “data subject access request”). This enables you to receive a
                                copy
                                of the personal data we hold about you and to check that we are lawfully processing
                                it.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'> <span className="text-secondary">Request correction</span> of the
                                personal data that we hold about you. This enables you to have any incomplete or
                                inaccurate
                                data we hold about you corrected, though we may need to verify the accuracy of the new
                                data
                                you provide to us.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'> <span className="text-secondary">Request erasure of your
                                personal data</span>. This enables you to ask us to delete or remove personal data where there
                                is
                                no good reason for us continuing to process it. You also have the right to ask us to
                                delete
                                or remove your personal data where you have successfully exercised your right to object
                                to
                                processing (see below), where we may have processed your information unlawfully or where
                                we
                                are required to erase your personal data to comply with local law. Note, however, that
                                we
                                may not always be able to comply with your request of erasure for specific legal reasons
                                which will be notified to you, if applicable, at the time of your request.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'> <span className="text-secondary">Object to processing</span> of
                                your
                                personal data where we are relying on a legitimate interest (or those of a third party)
                                and
                                there is something about your particular situation which makes you want to object to
                                processing on this ground as you feel it impacts on your fundamental rights and
                                freedoms.
                                You also have the right to object where we are processing your personal data for direct
                                marketing purposes. In some cases, we may demonstrate that we have compelling legitimate
                                grounds to process your information which override your rights and freedoms.</p>

                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'> <span className="text-secondary">Request restriction of
                                processing</span> of your personal data. This enables you to ask us to suspend the processing
                                of
                                your personal data in the following scenarios:</p>

                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'> • If you want us to
                                establish
                                the data’s accuracy.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>• Where our use of the data
                                is
                                unlawful but you do not want us to erase it.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>• Where you need us to hold
                                the
                                data even if we no longer require it as you need it to
                                establish, exercise or defend legal claims.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>• You have objected to our
                                use
                                of your data but we need to verify whether we have overriding
                                legitimate grounds to use it.</p>

                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'><span className="text-secondary">Request the transfer</span> of your
                                personal data to you or to a third party. We will provide to you, or a third party you
                                have
                                chosen, your personal data in a structured, commonly used, machine-readable format. Note
                                that this right only applies to automated information which you initially provided
                                consent
                                for us to use or where we used the information to perform a contract with you.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'> <span className="text-secondary">Withdraw consent at any
                                time </span>
                                where we are relying on consent to process your personal data. However, this will not
                                affect
                                the lawfulness of any processing carried out before you withdraw your consent. If you
                                withdraw your consent, we may not be able to provide certain products or services to
                                you. We
                                will advise you if this is the case at the time you withdraw your consent.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>If you wish to exercise any
                                of
                                the rights set out above, please contact us at <span className="text-secondary">legal@getolyves.com</span>.</p>
                        </div>
                        <div className="my-8">
                            <h2 className='font-cabin font-bold text-primary text-2xl my-6 mobile:text-xl'>No Usual Fee Required</h2>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.</p>
                            <h2 className='font-cabin font-bold text-primary text-2xl my-6 mobile:text-xl'>What We May Need From You</h2>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it.
                                We may also contact you to ask you for further information in relation to your request to speed up our response.</p>
                            <h2 className='font-cabin font-bold text-primary text-2xl my-6 mobile:text-xl'>Time Limit To Respond</h2>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>
                        </div>

                    </div>
                    <div className='w-[100%] my-16'>
                        <h1 className='font-cabin font-bold text-primary text-3xl my-8 mobile:text-2xl'>10.
                            GLOSSARY</h1>
                        <div className="my-8">
                            <h2 className='font-cabin font-bold text-primary text-2xl my-6 mobile:text-xl'>Lawful
                                Basis</h2>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'><span
                                className="text-secondary">Legitimate Interest</span> means the interest of our business
                                in conducting and managing our business to enable us to give you the best
                                service/product and the best and most secure experience. We make sure we consider and
                                balance any potential impact on you (both positive and negative) and your rights before
                                we process your personal data for our legitimate interests. We do not use your personal
                                data for activities where our interests are overridden by the impact on you (unless we
                                have your consent or are otherwise required or permitted to by law). You can obtain
                                further information about how we assess our legitimate interests against any potential
                                impact on you in respect of specific activities by contacting us.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'><span
                                className="text-secondary">Performance of Contract</span> means processing your data
                                where it is necessary for the performance of a contract to which you are a party or to
                                take steps at your request before entering into such a contract.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'><span
                                className="text-secondary">Comply with a legal obligation</span> means processing your
                                personal data where it is necessary for compliance with a legal obligation that we are
                                subject to.</p>
                        </div>

                    </div>
                    <div className='w-[100%] my-16'>
                        <h2 className='font-cabin font-bold text-primary text-3xl my-8 mobile:text-2xl'>Third
                            Parties</h2>
                        <div className="my-10">
                            <h2 className='font-cabin font-bold text-primary text-2xl my-6 mobile:text-xl'>Internal
                                Third Parties</h2>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>Other affiliates of Olyves
                                acting as joint controllers or processors and who are based outside Nigeria and provide
                                IT and system administration services and undertake leadership reporting.</p>
                        </div>


                        <div className="my=8">
                            <h2 className='font-cabin font-bold text-primary text-2xl my-6 mobile:text-xl'>External
                                Third
                                Parties</h2>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'> • Service providers acting
                                as
                                processors who provide IT and system administration services.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'> • Professional advisers
                                acting
                                as processors or joint controllers including lawyers, bankers, auditors and insurers who
                                provide consultancy, banking, legal, insurance and accounting services.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>• The Central Bank of
                                Nigeria,
                                Nigeria Data Protection Bureau, Corporate Affairs Commission, Federal Inland Revenue
                                Service, and other authorities acting as processors or joint controllers in Nigeria who
                                require reporting of processing activities in certain circumstances.</p>
                            <p className='font-lato text-lg text-text my-5  mobile:text-sm'>• Other third parties,
                                including
                                market researchers, fraud prevention agencies, price comparison sites and so on.</p>
                        </div>


                    </div>


                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default PrivacyPolicy