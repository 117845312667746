import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import React from 'react'
import './App.css';
import LandingPage from "./components/LandingPage/LandingPage";
import PrivacyPolicy from "./components/PrivacyPolicy";
import SignUp from "./components/SignUp";

function App() {
    return (
        <Router>
            <Routes>
                <Route exact path={"/"} element={<LandingPage/>} />
                <Route path={"/sign-up"} element={<SignUp/>}/>
                <Route path={"/privacy-policy"} element={<PrivacyPolicy/>}/>
            </Routes>
        </Router>

    );
}

export default App;
