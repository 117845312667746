import React, {useState} from "react";
import AWS from 'aws-sdk';
import axios from 'axios';
import {CgClose} from "react-icons/cg";
import logo from "../OlyvesImages/logo.png";
import {BsPerson} from "react-icons/bs";
import {HiOutlineMail} from "react-icons/hi";
import {RiArrowDropDownLine} from "react-icons/ri";
import {Link} from "react-router-dom";
import {FaFacebook, FaLinkedin, FaTwitter} from "react-icons/fa";
import {AiFillInstagram} from "react-icons/ai";
import {SlClose} from "react-icons/sl";



function SignUp() {
    const url =process.env.REACT_APP_BASE_URL
    const [isModalOpen, setModalOpen] = useState(false);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        fullName: '', email: '', role: ''
    });


    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData, [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        const validationErrors = validateForm(formData);
        e.preventDefault();
        setErrors({});

        try {
            const response = await axios.post(url, formData, {
                headers: {
                    "Access-Control-Allow-Origin": "*", // Required for CORS support to work
                    "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
                },
            });
            console.log(response)
        } catch (error) {
            console.log(error);
        }

        if (!formData.fullName || !formData.email || !formData.role) {
            alert('Please fill in all fields.');
            setModalOpen(false)
            setErrors(validationErrors)
            return;
        }

        if (Object.keys(validationErrors).length === 0) {
            setModalOpen(true)
            setFormData({
                fullName: '', email: '', role: ''
            });
        } else {
            setErrors(validationErrors);
        }
    };

    const validateForm = (data) => {
        let errors = {};

        // Validate first name
        if (data.fullName.trim() === '') {
            errors.fullName = 'Your name is required!';
        }


        // Validate email
        if (data.email.trim() === '') {
            errors.email = 'Email is required!';
        } else if (!isValidEmail(data.email)) {
            errors.email = 'Invalid email format!';
        }

        // Validate password
        if (data.role.trim() === '') {
            errors.role = 'Your role is required!';
        }

        return errors;
    };

    const isValidEmail = (email) => {
        // Basic email format validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleBlur = (e) => {
        const {name} = e.target;
        const validationErrors = validateField(name, formData[name]);

        setErrors((prevErrors) => ({
            ...prevErrors, [name]: validationErrors[name]
        }));
    };

    const validateField = (fieldName, value) => {
        let fieldErrors = {};

        // Validate individual field based on its name and value
        switch (fieldName) {
            case 'fullName':
                if (value.trim() === '') {
                    fieldErrors.fullName = 'Full name is required!';
                }
                break;
            case 'email':
                if (value.trim() === '') {
                    fieldErrors.email = 'Email is required!';
                } else if (!isValidEmail(value)) {
                    fieldErrors.email = 'Invalid email format!';
                }
                break;
            case 'role':
                if (value.trim() === '') {
                    fieldErrors.role = 'Your role is required!';
                }
                break;
            default:
                break;
        }

        return fieldErrors;
    };

    const isFormValid = formData["fullName"] !== '' && formData["email"] !== '' && formData["role"] !== '';

    return (<div className='flex justify-center items-center bg-[#F5F5F5] h-screen laptops:min-h-[1000px] mini-tablet:min-h-[1000px] '>

        <div className='flex justify-center items-center flex-col w-[50%] h-auto bg-white m-8 rounded-3xl shadow-box mini-tablet:w-[80%] tablets:w-[70%] laptops:w-[60%] mobile:w-full mobile:m-0 mobile:rounded-none mobile:shadow-none '>
            {isModalOpen && (<div className=" h-screen  laptops:h-full bg-modalBg fixed inset-0 flex flex-col items-center justify-center z-10">
                <div
                    className=" flex items-center flex-col h-[45%] w-auto  bg-white text-center  rounded-xl my-8 p-20 shadow-lg relative mini-tablet:h-[40%] tablets:h-auto laptops:h-auto mini-tablet:p-16 tablets:p-16 laptops:px-16 laptops:py-12 mobile:h-auto mobile:p-6 mobile:w-[90%] ">
                  <Link to={"/"}>
                    <SlClose size={25} className=' absolute right-4 top-4' onClick={closeModal} color='#8CA0A4'/>
                  </Link>
                    <h2 className="text-3xl text-primary font-cabin font-bold my-4  w-[70%] tablets:text-2xl mobile:text-lg ">Thank
                        you for joining our
                        waitlist</h2>
                    <div className='my-6 mobile:m-4 mobile:w-[85%] '>
                        <p className="text-text font-lato text-base mobile:text-xs mobile:m-2">You are one
                            step
                            closer to a simple payroll
                            solution that works.</p>
                        <p className="text-text font-lato text-base mobile:text-xs">You'll be one of the
                            first
                            persons to get
                            product updates.</p>
                    </div>
                    <div className='flex flex-col items-center my-4 mobile:w-[85%]'>
                        <p className='font-lato text-secondary font-bold text-base mobile:text-sm'>Join the
                            Olyves Community
                            for
                            New Updates</p>
                        <div
                            className='text-text flex justify-between items-center my-4 cursor-pointer w-[50%]  '>
                            <a href={"https://www.twitter.com/getolyves"} target="_blank" rel="noopener noreferrer" > <FaTwitter size="20px"/> </a>
                            <a href={" https://www.linkedin.com/company/getolyves"} target="_blank" rel="noopener noreferrer"><FaLinkedin   size="20px"/> </a>
                            <a href={" https://www.facebook.com/getolyves"} target="_blank" rel="noopener noreferrer" > <FaFacebook  size="20px"/> </a>
                            <a href={"https://www.instagram.com/getolyves"} target="_blank" rel="noopener noreferrer" > <AiFillInstagram   size="20px"/> </a>
                        </div>
                    </div>


                </div>

            </div>)}

            <div className='bg-topBar w-full h-[75px] flex justify-end px-10 mobile:h-[55px]  '>
                <Link to={"/"} className='flex justify-center items-center'>
                    <CgClose size={25} color='#071932'/>
                </Link>
            </div>
            <div className='flex flex-col justify-center items-center my-8 w-full mini-tablet:my-4 laptops:my-5 mobile:my-2 '>
                <div className='my-4 '>
                    <img className='h-[55px] w-[120px] laptops:h-[50px] laptops:w-[110px] mobile:h-[45px] mobile:w-[100px]' src={logo}/>
                </div>

                <div
                    className='h-auto w-[60%] rounded-3xl shadow-box my-4 flex flex-col items-center p-12 mobile:p-4 mini-tablet:w-[90%] tablets:w-[80%] laptops:w-[80%] mobile:w-full mobile:rounded-none mobile:shadow-none '>
                    <div className='text-center my-4 mobile:my-2'>
                        <h1 className='font-cabin font-bold text-3xl text-primary mobile:text-xl '>Ready, Set,
                            Soon!</h1>
                        <p className='font-lato text-text text-sm mobile:text-xs p-2'>Get a <span
                            className='text-secondary'>FREE 90-day</span> trial on product launch.</p>
                    </div>

                    <form onSubmit={handleSubmit}
                          className='my-4 w-[90%] mini-tablet:w-[90%] tablets:w-[90%] mobile:w-[95%] '>
                        <div className='my-3 '>
                            <div
                                className='flex items-center w-full h-[55px] flex border border-[ #ECF0F1] rounded-lg px-4 font-lato text-base text-primary mobile:h-[50px] mobile:text-sm'>
                                <BsPerson color="#BDC6D3" className='my-4' size={20}/>
                                <input
                                    className='h-[50px] w-[370px] px-3 my-0.5 outline-0 placeholder-[#BDC6D3] mobile:h-[40px]'
                                    placeholder='Full name'
                                    type="text"
                                    name="fullName"
                                    value={formData.fullName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                />
                            </div>
                            {errors.fullName && <span
                                className="font-lato text-red-600 text-base mobile:text-xs ">{errors.fullName}</span>}
                        </div>
                        <div className='my-7 '>
                            <div
                                className={`w-full h-[55px] flex border border-[ #ECF0F1] rounded-lg px-4 font-lato text-base text-primary mobile:h-[50px] mobile:text-sm`}>
                                <HiOutlineMail color="#BDC6D3" className='my-4' size={20}/>
                                <input
                                    className='h-[50px] w-[370px] px-3 my-0.5 outline-0 placeholder-[#BDC6D3] mobile:h-[40px]'
                                    placeholder='Work email address'
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                />
                            </div>
                            {errors.email && <span
                                className="font-lato text-red-600 text-base mobile:text-xs ">{errors.email}</span>}
                        </div>
                        <div className='my-7'>
                            <div
                                className={`w-full h-[55px] flex border border-[ #ECF0F1] rounded-lg px-4 font-lato text-base text-primary mobile:h-[50px] mobile:text-sm`}>
                                <BsPerson color="#BDC6D3" className='my-4 ' size={20}/>
                                <select
                                    className="block appearance-none w-full bg-white text-primary  px-4 py-2 pr-8 leading-tight outline-0 placeholder-[#BDC6D3] "
                                    name="role"
                                    value={formData.role}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select...</option>
                                    <option value="Accountant">Accountant</option>
                                    <option value="Chief Executive Officer">Chief Executive Officer</option>
                                    <option value="Human Resources">Human Resources</option>
                                    <option value="Operations Manager">Operations Manager</option>
                                </select>
                                <RiArrowDropDownLine color="#BDC6D3" className='my-3' size={30}/>
                            </div>

                            {errors.role && <span
                                className="font-lato text-red-600 text-base mobile:text-xs ">{errors.role}</span>}
                        </div>
                        <div
                            className={`text-center font-bold text-base font-lato w-full h-[55px] rounded-lg flex justify-center  items-center ${isFormValid ? 'bg-secondary text-white': 'bg-[#ECF0F1] text-primary ' }`}>
                            <button type="submit" className='w-full'>Join Waitlist
                            </button>
                        </div>

                    </form>

                </div>

                <div className='flex flex-col items-center my-6 mobile:my-4'>
                    <p className='text-text font-lato text-sm my-1'>Your personal information is secured. </p>
                    <p className='text-text font-lato text-sm my-1'>Here's our
                        <Link to={"/privacy-policy"}>
                            <span className='text-secondary'> Privacy Policy</span>
                        </Link>
                    </p>
                </div>

            </div>

        </div>
    </div>)
}

export default SignUp