import React from 'react';
import Logo from '../../OlyvesImages/logo.png'
import {Link} from "react-router-dom";


function NavBar() {

    return (
        <nav className='bg-white '>
            <div className=' flex justify-center items-center shadow-nav '>
                <div className=' w-[90%] h-24 py-6 flex justify-between mobile:h-20 '>
                    <div className='w-[50%] flex-shrink-0 ml-2'>
                        <img className='h-9 w-20 cursor-pointer mobile:h-9 mobile:w-18' src={Logo}/>
                    </div>
                    <div className='w-50%'>
                        <Link to={"/sign-up"}>
                            <button  className='font-lato font-bold text-sm text-white bg-secondary h-11 w-36 px-4 py-2 rounded-lg shadow-button
                            mobile:w-[100px] mobile:h-8 mobile:text-xs mobile:px-3 '
                            >Join Waitlist</button>
                        </Link>
                    </div>

                </div>
            </div>
        </nav>


    )
}

export default NavBar