import React from 'react';
import Logo from '../../OlyvesImages/logo.png'
import {FaTwitter, FaLinkedin, FaFacebook} from "react-icons/fa";
import {AiFillInstagram} from 'react-icons/ai'
import {Link} from "react-router-dom";


function Footer() {
    return (

            <div className='flex flex-col justify-center items-center my-24 w-screen'>
                <hr className=' w-[85%] my-8 '/>
                <div className='flex justify-between w-[85%] h-[120px]'>
                    <div className='flex flex-col justify-between items-start'>
                        <Link to={"/"}>
                            <img className='w-[90px] h-[40px] mb-5 cursor-pointer'  src={Logo}/>
                        </Link>

                        <div className=''>
                            <Link to={"/privacy-policy"}>
                                <p className='my-2 font-lato text-base text-text font-medium cursor-pointer'>Privacy Policy</p>

                            </Link>
                            <p className='my-2 font-lato text-base text-text font-medium cursor-pointer '>Lagos, Nigeria.</p>
                        </div>

                    </div>
                    <div className='flex flex-col  justify-center  items-end text-text'>
                        <p className='font-lato text-base cursor-pointer '>hello@getolyves.com</p>
                        <div className='text-text flex justify-between my-4 cursor-pointer items-end'>
                            <a href={"https://www.twitter.com/getolyves"} target="_blank" rel="noopener noreferrer" > <FaTwitter className='ml-5' size="20px"/> </a>
                            <a href={" https://www.linkedin.com/company/getolyves"} target="_blank" rel="noopener noreferrer" ><FaLinkedin className='ml-5'  size="20px"/> </a>
                            <a href={" https://www.facebook.com/getolyves"} target="_blank" rel="noopener noreferrer" > <FaFacebook className='ml-5' size="20px"/> </a>
                            <a href={"https://www.instagram.com/getolyves"} target="_blank" rel="noopener noreferrer" > <AiFillInstagram className='ml-5'  size="20px"/> </a>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Footer