import React from 'react';
import {Link} from "react-router-dom";


function FifthSection() {

    return (
        <div
            className='flex justify-center items-center w-full h-[350px] mini-tablet:h-[200px] tablets:h-[250px] mobile:h-auto '>
            <div className='flex justify-between items-center h-[60%] w-[65%] bg-[#C4FFCB] rounded-2xl px-20 shadow-box
             mini-tablet:w-[90%] mini-tablet:px-9 tablets:w-[80%] tablets:px-16
             laptops:w-[70%]
             mobile:flex-col mobile:w-[95%] mobile:px-4  mobile:justify-center mobile:items-center mobile:py-4
             '>
                <div className='w-[60%] mini-tablet:w-[70%]  mobile:w-full mobile:my-4 mobile:mx-8'>
                    <h1 className='font-cabin font-bold text-4xl text-primary mini-tablet:text-lg tablets:text-xl laptops:text-2xl mobile:text-lg mobile:mx-5 '>Want
                        to do Payroll Differently?</h1>
                    <h1 className='font-cabin font-bold text-4xl text-primary mini-tablet:text-lg tablets:text-xl laptops:text-2xl mobile:text-lg mobile:mx-5'>Roll
                        with Olyves</h1>
                </div>
                <div
                    className='w-[40%] mini-tablet:w-[30%] flex justify-end  mobile:w-full mobile:justify-center mobile:items-center mobile:m-6'>
                    <Link to={"/sign-up"} className='w-full flex justify-end'>
                        <button className='font-lato font-bold text-base text-white bg-primary h-14 w-40 px-4 py-2 rounded-lg shadow-button
                        mini-tablet:w-28 mini-tablet:text-sm mini-tablet:h-11
                        tablets:w-36 tablets:text-sm tablets:h-12
                        mobile:w-[95%]
                        '>Join Waitlist
                        </button>
                    </Link>
                </div>


            </div>
        </div>
    )
}

export default FifthSection