import React from 'react';

function SecondSectionTile(props) {
    const {IconImage, HeadingText, BodyText1, BodyText2} = props
    return (
        <div className='flex flex-col bg-white h-auto w-[48%] my-4 p-12 shadow-box rounded
         mini-tablet:w-[45%] mini-tablet:p-5
         tablets:w-[48%] tablets:p-8
        laptops:w-[48%] laptops:p-10
        mobile:w-full mobile:p-8 mobile:my-6
        '>
            <img className='h-[48px] w-[48px] mini-tablet:h-[36px] mini-tablet:w-[36px]
            tablets:h-[40px] tablets:w-[40px]
            laptops:h-[44px] laptops:w-[44px]
            mobile:h-[38px] mobile:w-[38px]
            ' src={IconImage}/>
            <div className='flex flex-col justify-between items-start text-left my-8 mini-tablet:my-3 tablets:my-4 laptops:my-6 mobile:my-4'>
                <h1 className='font-lato font-bold text-xl text-primary my-3 mini-tablet:text-base tablets:text-lg laptops:text-lg laptops:my-2 mobile:text-xl mobile:my-3'>{HeadingText}</h1>
                <p className='font-lato text-text text-base my-3 mini-tablet:text-xs tablets:text-sm laptops:text-sm mobile:text-sm' >{BodyText1}</p>
                <p className='font-lato text-text text-base my-2 mini-tablet:text-xs tablets:text-sm laptops:text-sm mobile:text-sm'>{BodyText2}</p>
            </div>
        </div>
    )
}

export default SecondSectionTile