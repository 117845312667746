import React from 'react';
import Dashboard from '../../OlyvesImages/Dashboard .png'
import Frame from '../../OlyvesImages/Frame 5585.png'
import Phone from '../../OlyvesImages/Phone.png'
import {Link} from "react-router-dom";

function HeroSection() {
    return (<div className='flex justify-center items-center overflow-x-hidden'>
        <div
            className='relative mt-24 mb-48 w-screen h-screen mini-tablet:h-auto tablets:h-auto laptops:h-auto mobile:h-auto mini-tablet:mb-10 tablets:mb-12 mobile:w-full  mobile:my-10 '>
            <div className='h-[600px] w-[600px] rounded-full bg-accent absolute -top-[50px] -left-[200px] tablets filter blur-[125.5px] mobile:hidden'></div>
            <div className=' relative flex h-full z-2 my-8 mini-tablet:my-12 mobile:flex-col'>
                <div
                    className='flex flex-col justify-evenly h-[90%] min-h-[400px] ml-28  w-[45%]
                    mini-tablet:ml-10 mini-tablet:w-[55%] mini-tablet:h-auto
                   tablets:ml-16 tablets:w-[50%] tablets:h-auto
                   laptops:ml-16 laptops:h-auto
                   mobile:mx-5 mobile:w-full mobile:mt-16 mobile:mb-4 mobile:px-3
                    '>
                    <div className='bg-white  flex w-[65%] h-16 shadow-box p-2 rounded-lg items-center
                    mini-tablet:w-[90%] mini-tablet:h-auto tablets:w-[90%] laptops:w-[85%] mobile:w-[90%]'>
                        <img
                            className='h-8 w-[25%] my-2 mini-tablet:h-5 mini-tablet:w-[20%] tablets:w-[28%] laptops:w-[25%] mobile:h-6 mobile:w-[25%] '
                            src={Frame}/>
                        <p className='font-lato font-medium text-sm text-primary px-4
                        mini-tablet:font-light mini-tablet:text-[11px] mini-tablet:px-1.5
                        tablets:font-normal  tablets:text-[11px] tablets:px-2 tablets:py-2
                        laptops:text-[12px]
                        mobile:font-normal mobile:text-[11px] mobile:px-1
                        '>Join
                            570+ HR personnel, business
                            owners, and
                            accountants on a <span
                                className='text-secondary font-semibold mini-tablet:font-normal tablets:font-normal'>90-day free trial</span>
                        </p>
                    </div>
                    <div className='w-[90%]  mini-tablet:w-[90%] laptops:w-[90%] mobile:w-screen mobile:my-8 '>
                        <h1 className='font-cabin text-primary font-bold text-7xl leading-[98px] tracking-wide
                        mini-tablet:text-[30px] mini-tablet:leading-[55px] mini-tablet:tracking-normal
                        tablets:text-[38px] tablets:leading-[65px] tablets:tracking-normal
                        laptops:text-[48px] laptops:leading-[75px]  laptops:tracking-normal
                        mobile:text-4xl mobile:leading-[50px] mobile:tracking-normal
                         '>Process Your</h1>
                        <h1 className='font-cabin text-primary font-bold text-7xl  leading-[98px] tracking-wide
                         mini-tablet:text-[30px] mini-tablet:leading-[55px] mini-tablet:tracking-normal
                        tablets:text-[38px] tablets:leading-[65px] tablets:tracking-normal
                        laptops:text-[48px]  laptops:tracking-normal
                        mobile:text-4xl mobile:leading-[50px] mobile:tracking-wide

                        '>Payroll <span className='text-secondary'>10X Faster</span></h1>
                        <h1 className='font-cabin text-primary font-bold text-7xl leading-[98px] tracking-wide
                         mini-tablet:text-[30px] mini-tablet:leading-[55px] mini-tablet:tracking-normal
                        tablets:text-[38px] tablets:leading-[65px] tablets:tracking-normal
                        laptops:text-[48px]  laptops:tracking-normal
                        mobile:text-4xl mobile:leading-[50px] mobile:tracking-wide
                         '>without Hassels</h1>

                    </div>
                    <div className='w-[70%] tablets:w-[80%] mini-tablet:[80%] mobile:w-[90%] '>
                        <p className='font-lato text-text text-base mb-8 mini-tablet:text-xs tablets:text-xs'>Are
                            you sick of spending hours
                            mowing
                            through endless rows of spreadsheets? You are not
                            alone.</p>
                        <p className='font-lato text-text text-base mt-8 mini-tablet:text-xs tablets:text-xs'>We
                            are building a seamless
                            payroll
                            processing platform that works for Nigerian businesses, HR
                            personnel, and employees.</p>
                    </div>
                    <Link to={"/sign-up"}>
                        <button
                            className='font-lato font-bold text-base text-white bg-secondary h-12 w-48 px-4 py-2 my-4 mobile:my-8 rounded shadow-button mini-tablet:h-10 mini-tablet:w-36 tablets:w-40 mobile:w-[90%]'>Join
                            Waitlist
                        </button>
                    </Link>
                </div>
                <div
                    className='relative w-[55%] mini-tablet:w-[45%] tablets:w-[45%] laptops:w-[50%] mobile:w-full mobile:pl-3 '>
                    <img className='h-[930px] w-[1100px] drop-shadow-2xl
                    mini-tablet:w-[600px] mini-tablet:h-[420px]
                    tablets:w-[700px] tablets:h-[520px]
                    laptops:h-[700px] laptops:w-[800px]
                    mobile:h-[400px] mobile:w-[680px] mobile:my-5 mobile:drop-shadow-none
                     ' src={Dashboard}/>
                    <img
                        className='absolute h-[880px] w-[550px] z-10 -left-[16%] top-[20%] drop-shadow-2xl
                        mini-tablet:h-[400px] mini-tablet:w-[250px] mini-tablet:-left-[30%] mini-tablet:top-[18%]
                        tablets:w-[320px] tablets:h-[510px] tablets:-left-[28%] tablet:top-[18%]
                        laptops:h-[700px] laptops:w-[450px] laptops:-left-[20%] laptops:top-[18%]
                        mobile:h-[400px] mobile:w-[230px] mobile:-left-[6%]
                        '
                        src={Phone}/>
                </div>
            </div>
        </div>
    </div>)
}

export default HeroSection